import React, { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"

type PopupProps = {
  children: React.ReactNode
  show: boolean
  onDismiss: () => void
}

export default function Popup(props: PopupProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/application-ui/overlays/notifications#component-fc4d5d4d23fdbfd7bbd531edf250e93a
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end md:p-3 pointer-events-none z-popup"
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={props.show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="-translate-y-0 opacity-0"
          enterTo="-translate-y-2 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full bg-secondary shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="ml-3 w-0 flex-1 pt-0.5">{props.children}</div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="rounded-md inline-flex text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                    onClick={props.onDismiss}
                  >
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
