import React, { useState, useRef } from "react"
import { encodeAsUrlString } from "../helpers/Strings"
import { SubmitButton } from "./button"
import Popup from "./popup"
import { Routes } from "../data/routes"

const Content = {
  header: "Learn how on-demand math tutoring works at schools like yours",
  formImageAltText: "Parent and child reading on smartphone",
  formFirstNameLabel: "Name",
  formEmailLabel: "Email",
  formSchoolLabel: "School",
  formRoleLabel: "I am a",
  formRoleSelect: "-- Select --",
  formRoleOptions: [
    "District Administrator",
    "Principal",
    "Teacher",
    "Tutor",
    "Student",
  ],
  formRoleOther: "Other",
  formOtherLabel: "What is your role?",
  formSubmitButton: "Send me the one-pager",
  onePager: "/downloads/Yup_Math_Tutoring_One_Pager.pdf",
  onePagerFilename: "Yup_Math_Tutoring_One_Pager.pdf",

  // Success
  successHeader: "Thank you for your interest!",
  successText:
    "The one-pager will be automatically downloaded. If the download doesn't start, please",
  successLink: "click here",
}

type TextFieldProps = {
  id: string
  label: string
  autoComplete?: string
  type?: string
  required?: boolean
  value: string
  onChange: (value: string) => void
}

function TextField(props: TextFieldProps) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/forms/input-groups#component-04a268fee12196d74c94e82a43106f8d
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(event.target.value)
  }

  return (
    <div className="flex space-x-2 items-center">
      <label htmlFor={props.id} className="text-sm font-medium text-white">
        {props.label}
        {props.required && <span className="ml-1">*</span>}
      </label>
      <input
        value={props.value ?? ""}
        type={props.type ?? "text"}
        name={props.id}
        id={props.id}
        className="border rounded-md p-2 mt-1 focus:ring-primary focus:border-primary flex-1 block w-full sm:text-sm border-gray-300"
        autoComplete={props.autoComplete}
        required={props.required ?? false}
        onChange={onChange}
      />
    </div>
  )
}

type MainPopupProps = {
  show: boolean
  onDismiss: () => void
}

export default function MainPopup(props: MainPopupProps) {
  const download = useRef<HTMLAnchorElement>(null)
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [school, setSchool] = useState("")
  const [role, setRole] = useState("")
  const [other, setOther] = useState("")
  const [submitted, setSubmitted] = useState(false)

  function onRoleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setRole(event.target.value)
  }

  function onSubmit(event: React.FormEvent) {
    event.preventDefault()
    fetch(Routes.sendme, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeAsUrlString({
        "form-name": "leadmagnet",
        first_name: firstName,
        email,
        school,
        role,
        other,
      }),
    }).then(() => {
      setSubmitted(true)
      download.current?.click()
    })
  }

  return (
    <Popup show={props.show} onDismiss={props.onDismiss}>
      <a
        ref={download}
        download={true}
        href={Content.onePager}
        className="hidden"
      />
      {submitted ? (
        <div>
          <h2 className="text-2xl font-extrabold tracking-tight text-white">
            {Content.successHeader}
          </h2>
          <p className="text-white my-2">
            {Content.successText}
            <a
              href={Content.onePager}
              target="_blank"
              className="mx-2 font-bold underline"
            >
              {Content.successLink}
            </a>
          </p>
        </div>
      ) : (
        <div>
          <h2 className="text-2xl font-extrabold tracking-tight text-white">
            {Content.header}
          </h2>
          <form className="space-y-2" onSubmit={onSubmit}>
            <TextField
              id="first_name"
              label={Content.formFirstNameLabel}
              value={firstName}
              required={true}
              autoComplete="given-name"
              onChange={setFirstName}
            />
            <TextField
              id="email"
              type="email"
              label={Content.formEmailLabel}
              value={email}
              required={true}
              autoComplete="email"
              onChange={setEmail}
            />

            <TextField
              id="school"
              label={Content.formSchoolLabel}
              value={school}
              onChange={setSchool}
            />
            <div className="flex space-x-2 items-center">
              <label htmlFor="role" className="text-sm font-medium text-white">
                {Content.formRoleLabel}
                <span className="ml-1">*</span>
              </label>
              <select
                name="role"
                id="role"
                required
                value={role}
                className="border rounded-md p-2 mt-1 focus:ring-primary focus:border-primary flex-1 block w-full sm:text-sm border-gray-300"
                onChange={onRoleChange}
              >
                <option value="" disabled>
                  {Content.formRoleSelect}
                </option>
                {Content.formRoleOptions.map(role => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
                <option value="Other">{Content.formRoleOther}</option>
              </select>
            </div>
            {role === "Other" && (
              <TextField
                id="other"
                label={Content.formOtherLabel}
                required={true}
                value={other}
                onChange={setOther}
              />
            )}
            <div className="text-center">
              <SubmitButton text={Content.formSubmitButton} />
            </div>
          </form>
        </div>
      )}
    </Popup>
  )
}
