import React, { Fragment, useState } from "react"
import { Hubspot } from "../helpers/Hubspot"
import { Transition } from "@headlessui/react"
import { QuestionMarkCircleIcon, MinusIcon } from "@heroicons/react/solid"

const HelpButton = () => {
  const [showButton, setShowButton] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  React.useEffect(() => {
    window.addEventListener("scroll", load)
    return () => window.removeEventListener("scroll", load)
  }, [])

  async function load() {
    setShowButton(true)
    window.removeEventListener("scroll", load)
  }

  function onShowPopup() {
    setShowPopup(true)
    Hubspot.load("#hubspot-popup")
  }

  const transitionProps = {
    enter: "transform ease-out duration-300 transition",
    enterFrom: "-translate-y-0 opacity-0",
    enterTo: "-translate-y-2 opacity-100",
    leave: "transition ease-in duration-100",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
    as: Fragment,
  }

  return (
    <>
      <Transition {...transitionProps} show={showButton}>
        <button
          className="fixed bottom-2 right-2 inline-flex justify-center items-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-transparent bg-secondary-500 text-white hover:bg-secondary-600"
          onClick={onShowPopup}
        >
          <QuestionMarkCircleIcon className="h-5 w-5 mr-1" aria-hidden="true" />
          Help
        </button>
      </Transition>

      <div className="z-popup fixed bottom-2 right-2 w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition {...transitionProps} show={showPopup}>
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="bg-secondary text-white flex justify-between py-2 px-4 items-center">
              <div className="font-bold text-lg">Chat with us</div>
              <button
                className="rounded-md inline-flex text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
                onClick={() => setShowPopup(false)}
              >
                <MinusIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="p-4">
              <div id="hubspot-popup" />
            </div>
          </div>
        </Transition>
      </div>
    </>
  )
}

export default HelpButton
