import Config from "./Config"

export const Hubspot = {
  loadScript() {
    if (document.getElementById("hubspot-snippet")) return
    return new Promise(resolve => {
      const script = document.createElement("script")
      script.setAttribute("id", "hubspot-snippet")
      script.onload = resolve
      script.src = `//js.hsforms.net/forms/embed/v2.js`
      document.head.appendChild(script)
    })
  },
  async load(target: string) {
    await this.loadScript()
    ;(window as any).hbspt.forms.create({
      region: "na1",
      portalId: Config.HubspotID,
      formId: Config.HubspotKey,
      target,
    })
  },
}

export default Hubspot
