/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { loadInspectlet } from "../helpers/Inspectlet"

import Header from "./header"
import HelpButton from "./HelpButton"
import MainBanner from "./MainBanner"
import MainPopup from "./MainPopup"
import Footer from "./footer"

type LayoutProps = {
  children: React.ReactNode
  hidePopup?: boolean
  hideDemoLink?: boolean
  onScroll?: () => void
  hideHeader?: boolean
}

const Layout = (props: LayoutProps) => {
  const [showPopup, setShowPopup] = useState(false)

  React.useEffect(() => {
    window.addEventListener("scroll", load)
    return () => window.removeEventListener("scroll", load)
  }, [])

  function load() {
    loadInspectlet()

    if (!props.hidePopup && !sessionStorage["hidepopup"]) {
      setShowPopup(true)
    }

    props.onScroll?.()

    window.removeEventListener("scroll", load)
  }

  function onDismissPopup() {
    setShowPopup(false)
    sessionStorage["hidepopup"] = true
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <MainBanner mobile={false} />
      {!props.hideHeader && (
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          hideDemoLink={props.hideDemoLink || false}
        />
      )}
      <MainBanner mobile={true} />
      <main className="bg-background">{props.children}</main>
      <HelpButton />
      <MainPopup show={showPopup} onDismiss={onDismissPopup} />
      <Footer></Footer>
    </>
  )
}

export default Layout
