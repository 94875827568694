import * as React from "react"
import { Link } from "gatsby"

import Button from "./button"

import { Fragment, useState } from "react"
import { Dialog, Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

import { Routes, ExternalRoutes } from "../data/routes"
import Img from "./img"

type HeaderProps = {
  hideDemoLink?: boolean
}

const YupNavLogo = "yup-nav-logo.svg"

const navigation = [
  {
    name: "What's Yup?",
    href: "#",
    children: [
      { name: "How Yup Works", href: Routes.features },
      { name: "How We Teach", href: Routes.learningFundamentals },
      { name: "Subjects We Teach", href: Routes.mathTutoring },
      { name: "Plans", href: Routes.plans },
      { name: "School Day Integrations", href: Routes.highDosageTutoring },
      { name: "Our Tutors", href: Routes.ourTutors },
      { name: "Student Privacy", href: Routes.privacy },
    ],
  },
  { name: "Administrators", href: Routes.administrators, children: [] },
  { name: "Teachers", href: Routes.teachers, children: [] },
  {
    name: "Resources",
    href: "#",
    children: [
      { name: "Blog", href: Routes.blog },
      { name: "Videos and Media", href: Routes.resources },
      { name: "See a student using Yup", href: Routes.watchDemo },
      { name: "Help Center", href: ExternalRoutes.helpCenter },
      {
        name: "Webinar - Addressing unfinished learning",
        href: Routes.watchWebinar,
      },
    ],
  },
  { name: "Become a Tutor", href: Routes.tutors, children: [] },
]

const userNavigation = [
  { name: "I'm an educator", href: "https://schools.yup.com" },
  { name: "I'm a student", href: "https://student.yup.com" },
  { name: "I'm a parent", href: "https://family.yup.com" },
  { name: "I'm a Yup tutor", href: "https://tutor.yup.com" },
]

const plansLink = {
  name: "Plans",
  options: [
    { name: "Individual", href: Routes.plans },
    { name: "Schools", href: Routes.schools },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function PlansDropDown() {
  const [open, setOpen] = useState(false)
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="flex items-center text-sm font-medium text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
            <span className="font-display text-secondary uppercase">
              {plansLink.name}
            </span>
            <ChevronDownIcon
              className="ml-1 h-5 w-5 text-secondary"
              aria-hidden="true"
            />
          </Menu.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {plansLink.options.map(item => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default function Header(props: HeaderProps) {
  const [open, setOpen] = useState(false)

  return (
    <header className="shadow-md flex-shrink-0 sticky top-0 h-16 bg-white w-full z-toolbar">
      <div className="max-w-7xl mx-auto flex items-center">
        {/* Logo area */}
        <div className="absolute inset-y-0 left-0 lg:static lg:flex-shrink-0">
          <Link
            to={Routes.home}
            className="flex items-center justify-center h-16 w-16 ml-5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 lg:w-20"
          >
            <Img className="h-8 w-auto" src={YupNavLogo} alt="Yup" />
          </Link>
        </div>

        {/* Menu button area */}
        <div className="absolute inset-y-0 right-0 pr-4 flex items-center sm:pr-6 lg:hidden">
          {props.hideDemoLink && <PlansDropDown />}
          {/* Mobile menu button */}
          <button
            type="button"
            className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
            onClick={() => setOpen(!open)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Desktop nav area */}
        <div className="hidden lg:min-w-0 lg:flex-1 lg:flex lg:items-center">
          <div className="ml-10 pr-4 flex-shrink-0 flex flex-grow items-center justify-end space-x-10">
            <nav aria-label="Global" className="flex space-x-10">
              {navigation.map(item =>
                item.children.length ? (
                  <Menu key={item.name} as="div" className="relative text-left">
                    {({ open }) => (
                      <>
                        <Menu.Button className="flex items-center text-sm font-medium text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                          <span>{item.name}</span>
                          <ChevronDownIcon
                            className="ml-1 h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </Menu.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute z-30 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div className="py-1">
                              {item.children.map(child => (
                                <Menu.Item key={child.name}>
                                  {({ active }) => (
                                    <Link
                                      to={child.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {child.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-sm font-medium text-gray-900"
                  >
                    {item.name}
                  </Link>
                )
              )}
              {!props.hideDemoLink && <PlansDropDown />}
            </nav>
            <div className="flex flex-grow items-center justify-end space-x-8">
              <Menu as="div" className="relative inline-block text-left">
                {({ open }) => (
                  <>
                    <Menu.Button className="flex items-center text-sm font-medium text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600">
                      <span>Login</span>
                      <ChevronDownIcon
                        className="ml-1 h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="py-1">
                          {userNavigation.map(item => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 z-50 lg:hidden overflow-scroll"
            open={open}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
              enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
              enterTo="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
              leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
              leaveTo="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            >
              <nav
                className="fixed z-50 inset-0 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg overflow-scroll"
                aria-label="Global"
              >
                <div className="h-16 flex items-center justify-between px-4 sm:px-6">
                  <Link to={Routes.home}>
                    <Img
                      className="block h-8 w-auto"
                      src={YupNavLogo}
                      alt="Yup"
                    />
                  </Link>
                  <button
                    type="button"
                    className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                  <Link
                    to="#"
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                  >
                    Login
                  </Link>
                  {userNavigation.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="border-t border-gray-200 pt-4 max-w-8xl mx-auto py-3 px-2 sm:px-4 pb-3">
                  {navigation.map(item => (
                    <Fragment key={item.name}>
                      <Link
                        to={item.href}
                        className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                      >
                        {item.name}
                      </Link>
                      {item.children.map(child => (
                        <Link
                          key={child.name}
                          to={child.href}
                          className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100"
                        >
                          {child.name}
                        </Link>
                      ))}
                    </Fragment>
                  ))}
                </div>
              </nav>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </div>
    </header>
  )
}
