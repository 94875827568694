export function encodeAsUrlString(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export function interpolateString(
  str: string,
  variables: { [key: string]: string | number }
) {
  const originalStr = str
  const iter = str.matchAll(/{{\s*(\w+)\s*}}/g)
  let group = iter.next()
  while (!group.done) {
    const [match, key] = group.value
    if (key in variables) {
      str = str.replace(match, String(variables[key]))
    } else {
      console.warn(
        `interpolateString: ${key} was not provided for string '${originalStr}'`
      )
    }
    group = iter.next()
  }
  return str
}
